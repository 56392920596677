import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const EnrollIcon = (props: IconProps) => (
  <IconBox {...props}>
    <g clipRule="evenodd" fillRule="evenodd">
      <path d="M2.54214 8.67884c1.62771-1.62772 3.83535-2.54216 6.13727-2.54216H28.9314c1.5978 0 2.8931 1.29531 2.8931 2.89316 0 1.59786-1.2953 2.89316-2.8931 2.89316H8.67941a2.89303 2.89303 0 00-2.04576.8474 2.8933 2.8933 0 00-.84738 2.0458v40.5043c0 .7673.30481 1.5032.84738 2.0458a2.89303 2.89303 0 002.04576.8474H49.1833a2.8932 2.8932 0 002.0458-.8474 2.89361 2.89361 0 00.8474-2.0458V35.0683c0-1.5978 1.2953-2.8931 2.8931-2.8931s2.8931 1.2953 2.8931 2.8931v20.2522c0 2.3019-.9144 4.5096-2.5421 6.1373C53.6929 63.0856 51.4852 64 49.1833 64H8.67941c-2.30192 0-4.50957-.9144-6.13727-2.5422C.91444 59.8301 0 57.6224 0 55.3205V14.8162c0-2.302.91443-4.5096 2.54214-6.13736z" />
      <path d="M54.9696 5.78633c-.8604 0-1.6856.3418-2.294.9502L25.7575 33.655l-1.5293 6.1172 6.1172-1.5293 26.9181-26.9184a3.24416 3.24416 0 000-4.58797 3.24401 3.24401 0 00-2.2939-.9502zm-6.3855-3.14136C50.2777.95143 52.5746 0 54.9696 0c2.395 0 4.6919.95142 6.3855 2.64497C63.0486 4.33852 64 6.63546 64 9.0305c0 2.395-.9514 4.692-2.6449 6.3855L33.8703 42.9011a2.8934 2.8934 0 01-1.3441.761l-11.5726 2.8932c-.9859.2465-2.0288-.0424-2.7474-.761-.7186-.7186-1.0075-1.7616-.761-2.7475l2.8931-11.5727c.1272-.5087.3903-.9733.761-1.344z" />
    </g>
  </IconBox>
);

export default EnrollIcon;
