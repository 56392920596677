import React, { FC, ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { ErrorMessage, useField } from 'formik';
import { isFunctionComponentElement } from 'src/lib/type.utils';
import { StyleProps } from 'src/lib/styleProps';
import { is } from 'ramda';

type ChildType = 'TextControl' | 'TextField' | 'CheckboxControl' | string;

// todo: fix this and styling issues?
const childTypeSpacing: Record<ChildType, ReturnType<typeof css>> = {
  TextField: css`
    margin-top: -0.75rem;
    padding: 0 0.4375rem 0;
  `,
  TextControl: css`
    margin-top: -0.75rem;
    padding: 0 0.4375rem 0;
  `,
  CheckboxControl: css`
    margin-top: -0.5rem;
    padding: 0.25rem 1.25rem 0 2.125rem;
  `,
};

const Description = styled.div<{
  childType?: ChildType;
}>`
  color: ${colors.blue};
  font-size: 0.625rem;
  line-height: 0.875rem;
  opacity: 0.5;

  min-height: 1.25rem;
  padding: 0 0.4375rem 0;
  margin-bottom: 0.375rem;

  ${({ childType }) => (childType ? childTypeSpacing[childType] : '')};
`;

const ErrorContainer = styled(Description)`
  color: ${colors.red};
  opacity: 1;
`;

const getDisplayName = (a: ReactNode) => {
  if (!isFunctionComponentElement(a)) {
    return '';
  }
  return a.type.displayName;
};

export interface FormItemProps {
  description?: string;
  name: string;
}

export const FormItem: FC<FormItemProps & StyleProps> = ({
  className,
  style,
  description,
  name,
  children,
}) => {
  const [, meta] = useField(name);

  const hasError = meta.error && meta.touched;

  const errorFieldName = useMemo(
    () => (meta.error && is(Object, meta.error) ? `${name}.${Object.keys(meta.error)[0]}` : name),
    [meta, name],
  );

  return (
    <>
      {children}

      {!hasError && (
        <Description className={className} style={style} childType={getDisplayName(children)}>
          {description}
        </Description>
      )}

      {hasError && (
        <ErrorContainer className={className} style={style} childType={getDisplayName(children)}>
          <ErrorMessage name={errorFieldName} component="div" />
        </ErrorContainer>
      )}
    </>
  );
};
