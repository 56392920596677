import styled, { css } from 'styled-components';
import { ButtonWithLoader } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';

/**
 * Todo requires refactor / renaming / moving as proper variant
 */
export const AuthButton = styled(ButtonWithLoader)`
  display: block;
  margin: 0 auto;
  width: 100%;

  ${media.w.min.px576(css`
    width: 12.5rem;
  `)};

  ${media.w.min.px992(css`
    margin: 0;
  `)};
`;
