import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { usePartnerQuery } from 'src/common/hooks/usePartnerQuery';
import Image from 'next/image';
import { CalculatedImageDimensions } from 'src/common/models/image';

const Partner = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-areas: 'image' 'text';
  grid-row-gap: 0.375rem;

  ${media.w.min.px576(css`
    align-items: center;
    grid-template-areas: 'text image';
    grid-column-gap: 0.375rem;
  `)};
`;

const PartnerText = styled.div`
  font-size: 0.75rem;
  color: ${colors.pfp3bis};
  text-transform: uppercase;
  font-weight: 600;
  grid-area: text;
  letter-spacing: 0.15em;
  line-height: 1rem;
`;

const PartnerImage = styled.div<CalculatedImageDimensions>`
  height: ${({ $imageHeight }) => `${$imageHeight}rem`};
  width: ${({ $imageWidth }) => `${$imageWidth}rem`};
  max-width: 100%;
  position: relative;
  grid-area: image;
  justify-self: flex-end;

  ${media.w.min.px576(css`
    justify-content: flex-start;
  `)};
`;

export interface AuthPartnerProps {
  partnerCode: string;
}

export const AuthPartner: FC<AuthPartnerProps> = ({ partnerCode }) => {
  const { data } = usePartnerQuery(partnerCode);
  const imageHeight = 2;
  const calculatedImageWidth = useMemo(() => {
    if (data?.partnerLogo) {
      return Math.round((data?.partnerLogo.width / data?.partnerLogo.height) * imageHeight);
    }
  }, [data, imageHeight]);

  return (
    <Partner>
      {data?.partnerLogo && (
        <>
          <PartnerText>partner platformy</PartnerText>
          <PartnerImage $imageHeight={imageHeight} $imageWidth={calculatedImageWidth!}>
            <Image src={data.partnerLogo.url} alt="partner" layout="fill" objectFit="contain" />
          </PartnerImage>
        </>
      )}
    </Partner>
  );
};
