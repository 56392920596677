import { FunctionComponentElement, ReactElement, ReactNode } from 'react';

export function isReactElement(children: ReactNode): children is ReactElement {
  return (
    children != null &&
    Object.prototype.hasOwnProperty.call(children, 'type') &&
    Object.prototype.hasOwnProperty.call(children, 'props')
  );
}

export function isFunctionComponentElement(
  children: ReactNode,
): children is FunctionComponentElement<any> {
  return isReactElement(children) && Object.prototype.hasOwnProperty.call(children, 'ref');
}
