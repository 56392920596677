import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';

const StyledMuiTextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    border-top: 0.25rem solid transparent;
    display: flex;
    margin-bottom: 0.75rem;
  }

  & .MuiFormLabel-root {
    color: ${colors.blue};
  }

  & .MuiInputBase-input {
    ${media.w.min.px768(css`
      font-size: 0.75rem;
    `)};
  }

  & .MuiInputBase-root {
    color: ${colors.blue};
  }

  & .MuiInputLabel-outlined {
    transform: translate(0.875rem, 0.9375rem) scale(1);

    ${media.w.min.px768(css`
      font-size: 0.875rem;
    `)};

    &.MuiInputLabel-shrink {
      transform: translate(1.1875rem, -0.375rem) scale(0.85);
    }
  }

  & .MuiOutlinedInput-input {
    padding: 0.75rem 0.875rem 0.6875rem 0.875rem;

    &:-webkit-autofill {
      box-shadow: 0 0 0 6.25rem ${lighten(0.2, colors.pfp3)} inset;
    }
  }

  & .MuiOutlinedInput-inputMultiline {
    min-height: 5rem;
    padding: 0;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0.0625rem solid ${colors.blue05};

    & > legend {
      font-size: 0.875rem;

      ${media.w.min.px768(css`
        font-size: 0.75rem;
      `)};

      span {
        padding: 0 0.625rem;
      }
    }
  }

  & .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.blue05};
    }
  }

  & .MuiOutlinedInput-root.Mui-disabled,
  & .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.blue};
      border-width: 0.0625rem;
    }
  }

  & .MuiOutlinedInput-root.Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.red};
    }
  }
`;

export const TextField = (props: TextFieldProps) => (
  <StyledMuiTextField
    InputLabelProps={{
      shrink: true,
    }}
    variant="outlined"
    {...props}
  />
);

TextField.displayName = 'TextField';
