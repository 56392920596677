import React, { FC } from 'react';
import { Seo } from 'src/public/components/Seo';
import { LogoLoginDesktop } from 'src/common/images/LogoLoginDesktop';
import { LogoLoginMobile } from 'src/common/images/LogoLoginMobile';
import { LogoVerticalCentered } from 'src/common/images/LogoVerticalCentered';
import { Footer } from 'src/common/components/Footer';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { rem } from 'polished';
import { gutter } from 'src/config/layout';
import { HomepageRoute } from 'src/public/publicRoutes';
import { ScrollToTop } from 'src/common/components/ScrollToTop';
import { CookiePolicyBar } from 'src/common/components/CookiePolicyBar';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { AuthPartner } from 'src/public/components/auth/AuthPartner';
import { useAvailablePartner } from 'src/common/hooks/useAvailablePartner';
import { ClientOnly } from 'src/lib/SSR/ClientOnly/ClientOnly';

const Container = styled.div`
  padding-bottom: 2.25rem;

  ${media.w.min.px576(css`
    padding: 0 ${rem(gutter)} 2.25rem;
  `)};
`;

const Logo = styled.div<{ partnerAvailable: boolean }>`
  display: block;
  margin: 0.625rem 0.75rem 2.375rem;

  ${media.w.min.px576(css`
    margin: 8vh 0 7vh;
    text-align: center;
  `)};

  ${media.w.min.px992(css`
    margin: 6.5vw 0;
  `)};

  ${media.w.min.px1440(css`
    margin: 5.875rem 0;
  `)};

  ${({ partnerAvailable }) =>
    partnerAvailable &&
    css`
      display: flex;
      justify-content: space-between;
      max-width: 72.1875rem;
      margin: 0.625rem 0.75rem 1.25rem;

      ${media.w.min.px992(css`
        margin: 6.5vw auto;
      `)};

      ${media.w.min.px576(css`
        align-items: flex-end;
      `)};
      ${media.w.max.px576(css`
        height: 3.375rem;
      `)};
    `}

  ${LogoLoginDesktop} {
    ${media.w.max.px992(css`
      display: none;
    `)};
  }

  ${LogoLoginMobile} {
    ${media.w.min.px576(css`
      display: none;
    `)};
  }

  ${LogoVerticalCentered} {
    ${media.w.max.px576(css`
      display: none;
    `)};

    ${media.w.min.px992(css`
      display: none;
    `)};
  }
`;

export interface AuthLayoutProps {
  title: string;
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, title }) => {
  const { data } = useAvailablePartner();

  return (
    <>
      <Container>
        <Seo title={title} />

        <Logo partnerAvailable={!!data?.partnerCode}>
          <GatsbyLink to={HomepageRoute}>
            <LogoLoginDesktop />
            <LogoLoginMobile />
            <LogoVerticalCentered />
          </GatsbyLink>
          {!!data?.partnerCode && <AuthPartner partnerCode={data?.partnerCode} />}
        </Logo>

        {children}
      </Container>

      <ClientOnly>
        <CookiePolicyBar />
      </ClientOnly>
      <Footer />
      <ScrollToTop />
    </>
  );
};
